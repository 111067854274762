export const institutionCodes = [
  { code: 'AM', label: 'Australian Museum - OZCAM' },
  { code: 'ANSP', label: 'Academy of Natural Sciences at Philidelphia' },
  { code: 'ANSP09', label: 'Academy of Natural Sciences at Philidelphia' },
  { code: 'ANSPj', label: 'Academy of Natural Sciences at Philidelphia' },
  { code: 'BPBM', label: 'Bernice Pauahi Bishop Museum, Department of Natural Sciences - Bishop Museum Natural Sciences Data' },
  { code: 'CAS', label: 'California Academy of Sciences' },
  { code: 'CBM', label: 'Natural History Museum and Institute - Chiba' },
  { code: 'CMN', label: 'Canadian Heritage Information Network - Canadian Museum of Nature Fish Collection' },
  { code: 'CSIRO', label: 'CSIRO Ichthyology - OZCAM' },
  { code: 'CU', label: 'Cornell University Museum of Vertebrates (CUMV) - Fish Collection' },
  { code: 'DGR', label: 'Arctos - DGR Fishes Specimens' },
  { code: 'FMNH', label: 'Field Museum - FMNH Fish Collection' },
  { code: 'FSBC', label: 'Florida Fish and Wildlife Conservation Commission' },
  { code: 'IBUNAM', label: 'Universidad Nacional Autonoma de Mexico - IBiologia - CNPE/Coleccion Nacional de Peces' },
  { code: 'KAUM', label: 'Kagoshima University Museum' },
  { code: 'KU', label: 'University of Kansas Biodiversity Institute - Fish Collection' },
  { code: 'LACM', label: 'Los Angeles County Museum of Natural History (LACM) - Vertebrate specimens' },
  { code: 'LSUMZ', label: 'Louisiana State University Museum of Zoology' },
  { code: 'NRM', label: 'Swedish Museum of Natural History' },
  { code: 'MCZ', label: 'Harvard University Museum of Comparative Zoology' },
  { code: 'MHNL', label: 'GBIF-MNHN (Paris) - Ichtyologie' },
  { code: 'MHNLR', label: 'GBIF-MNHN (Paris) - Ichtyologie' },
  { code: 'MHP', label: 'Sternberg Museum of Natural History - Fish Collection' },
  { code: 'MMNS', label: 'Mississippi Museum of Natural Science' },
  { code: 'MNHN', label: 'GBIF-MNHN (Paris) - Ichtyologie' },
  { code: 'MOM', label: 'GBIF-MNHN (Paris) - Ichtyologie' },
  { code: 'MSUM', label: 'Michigan State University Museum (MSUM) - Vertebrate specimens' },
  { code: 'MVZ', label: 'Arctos - MVZ Hildebrand Specimens' },
  { code: 'MZS', label: 'GBIF-MNHN (Paris) - Ichtyologie' },
  { code: 'NCSM', label: 'North Carolina State Museum of Natural Sciences - North Carolina State Museum of Natural Sciences Fishes Collection' },
  { code: 'NMV', label: 'Museum Victoria - OZCAM' },
  { code: 'NT', label: 'Northern Territory Museum and Art Gallery - OZCAM' },
  { code: 'OKMNH', label: 'Sam Noble Oklahoma Museum of Natural History - Fish Specimens' },
  { code: 'OSUM', label: 'Ohio State University - Fish Division' },
  { code: 'QM', label: 'Queensland Museum - OZCAM' },
  { code: 'QVMAG', label: 'Queen Victoria Museum Art Gallery' },
  { code: 'ROM', label: 'Royal Ontario Museum - Fish specimens' },
  { code: 'SAMA', label: 'South Australian Museum - OZCAM' },
  { code: 'SBMNH', label: 'Santa Barbara Museum of Natural History' },
  { code: 'TCWC', label: 'Texas Cooperative Wildlife Collection (TCWC) - Ichthyology Specimens' },
  { code: 'TNHC', label: 'Texas Natural History Science Center - Texas Natural History Collections (TNHC)' },
  { code: 'TMAG', label: 'Tasmanian Museum and Art Gallery - OZCAM' },
  { code: 'TU', label: 'Tulane University Museum of Natural History - Royal D. Suttkus Fish Collection' },
  { code: 'UA', label: 'University of Alberta - University of Alberta Museums, Ichthyology Collection' },
  { code: 'UAFC', label: 'University of Arkansas Collections Facility, UAFMC' },
  { code: 'UAM', label: 'Arctos - UAM Fishes Specimens' },
  { code: 'UCBL', label: 'GBIF-MNHN (Paris) - Ichtyologie' },
  { code: 'UCM', label: 'University of Colorado Museum of Natural History Fish Collection' },
  { code: 'UCZM', label: 'University Museum of Zoology Cambridge (UMZC) - Zoological specimens' },
  { code: 'UF', label: 'Florida Museum of Natural History' },
  { code: 'UT', label: 'University of Tennessee-Etnier Ichthyological Research Collection' },
  { code: 'UMMZ', label: 'University of Michigan Museum of Zoology' },
  { code: 'UNSM', label: 'University of Nebraska State Museum' },
  { code: 'USNM', label: 'National Museum of Natural History, Smithsonian Institution - NMNH Vertebrate Zoology Fishes Collections' },
  { code: 'UWFC', label: 'University of Washington Fish Collection - Fish specimens' },
  { code: 'WNMU', label: 'Arctos - WNMU Fishes Specimens' },
  { code: 'YPM', label: 'Yale University Peabody Museum - Peabody Ichthyology DiGIR Service' }
];
